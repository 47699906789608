import {
  post
} from "../Http";

export default {
  // 获取活动管理员信息
  getAdminDetail: params =>
    post(`/admin/v1/admin/detail?adminId=${params}`),
  // 获取活动管理员信息列表
  getAdminList: params =>
    post(`/admin/v1/admin/page`, params),
  // 新建活动管理员信息
  createAdminDetail: params =>
    post(`/admin/v1/admin/create`, params),
  // 新建活动管理员信息
  updateAdminDetail: params =>
    post(`/admin/v1/admin/update`, params),
  // 新建活动管理员信息
  deleteAdminDetail: params =>
    post(`/admin/v1/admin/delete?adminId=${params}`),
  // 获取活动页url和域名信息
  getConfigDetail: params =>
    post(`/admin/v1/refresh/show_config`),
  // 修改url或域名信息
  updateConfigDetail: params =>
    post(`/admin/v1/refresh/switch_config`, params),

  /**
   * @method 获取活动列表
   * @param {*} actName 搜索名称
   * @param {*} id 搜索ID
   * @param {*} page 当前页码
   * @param {*} size 当前页总条数
   */
  getActivityList: params =>
    post(`/admin/v1/activity/list`, params),
  ExportActivityList: params =>
    post(`/admin/v1/activity/export`, params),

  /**
   * @method 获取活动详情
   * @param {*} id 活动ID
   */
  getActivityDetails: params =>
    post(`/admin/v1/activity/get?id=${params}`, {}, false),

  /**
   * @method 结束活动
   * @param {*} id 活动ID
   */
  postActivityFinish: params =>
    post(`/admin/v1/activity/finish?id=${params}`),

  /**
   * @method 开启活动
   * @param {*} id 活动ID
   */
  postActivityStart: params =>
    post(`/admin/v1/activity/start?id=${params}`),

  /**
   * @method 删除活动
   * @param {*} id 活动ID
   */
  delActivityStart: params =>
    post(`/admin/v1/activity/delete?id=${params}`),

  /**
   * @method 保存活动
   * @param {*} id 活动ID
   */
  postActivitySave: params =>
    post(`/admin/v1/activity/save`, params),

  /**
   * @method 查询广告位信息
   * @param {*} actId 活动ID
   */
  getActivityAdvList: params =>
    post(`/admin/v1/activity/adv/list?actId=${params}`),
  /**
   * @method 保存修改广告位信息
   * @param {*} actId 活动ID
   */
  saveActivityAdvList: params =>
    post(`/admin/v1/activity/adv/save`, params),

  /**
   * @method 查询导航设置信息
   * @param {*} actId 活动ID
   */
  getActivityNavList: params =>
    post(`/admin/v1/activity/navigate/list?actId=${params}`),

  /**
   * @method 更新导航设置
   * @param {Array}
   */
  postNavUpdate: params =>
    post(`/admin/v1/activity/navigate/update`, params),

  /**
   * @method 高级设置-分享设置-获取
   * @param {*} actId 活动ID
   */
  getActivityShareInfo: params =>
    post(`/admin/v1/activity/share_info/get?actId=${params}`),

  /**
   * @method 高级设置-分享设置
   * @param {integer} id 活动ID
   * @param {string} linkDesc 分享描述
   * @param {string} linkPoster 分享封面
   * @param {string} linkTitle 分享标题
   */
  postActivityShare: params =>
    post(`/admin/v1/activity/share_info/save`, params),

  /**
   * @method 获取数据优化列表
   * @param {*} actId 活动ID
   */
  getActivityOptimizeData: params =>
    post(`/admin/v1/activity/optimize/get?actId=${params}`),
  saveActivityOptimizeData: params =>
    post(`/admin/v1/activity/optimize/save`, params),

  /**
   * @method 导购员列表
   * @param {*} actId 活动ID
   * @param {*} mobile 手机号
   * @param {*} name 导购员姓名
   * @param {*} page 查询页数
   * @param {*} recharged ？
   * @param {*} size 每页数量
   * @param {*} type ？？
   * @param {*} userId 导购员Id
   */
  getQueryKpiUserList: params =>
    post(`/admin/v1/guide/query_kpi_user_list`, params),
  /**
   * @method 导出导购员列表
   * @param {*} actId 活动ID
   * @param {*} mobile 手机号
   * @param {*} name 导购员姓名
   * @param {*} page 查询页数
   * @param {*} recharged ？
   * @param {*} size 每页数量
   * @param {*} type ？？
   * @param {*} userId 导购员Id
   */
  getExportQueryKpiUser: params =>
    post(`/admin/v1/guide/export_kpi_user_list`, params),

  // 品牌列表，根据活动id、类目id、品牌名称查询
  getActivityBrandList: params =>
    post(`/admin/v1/activity/brand/list`, params, false),
  // 门店列表，根据品牌id查询
  getActivityBrandShopList: params =>
    post(`/admin/v1/activity/brand/shop/list`, params),
  // 军团列表
  getBrandCategoryList: params =>
    post(`/admin/v1/activity/brand/category/list`, params),
  // 新建或编辑品牌
  SaveActivityBrand: params =>
    post(`/admin/v1/activity/brand/save`, params),
  // 新建或编辑军团
  SaveActivityCategory: params =>
    post(`/admin/v1/activity/brand/category/save`, params),
  // 删除军团
  delActivityCategory: params =>
    post(`/admin/v1/activity/brand/category/delete?categoryIds=${params}`),
  // 批量删除品牌
  delActivityBrand: params =>
    post(`/admin/v1/activity/brand/delete?brandIds=${params}`),
  // 批量删除品牌门店
  delActivityShop: params =>
    post(`/admin/v1/activity/brand/shop/delete?shopIds=${params}`),

  // 门店列表
  getBrandShopList: params =>
    post(`/admin/v1/activity/brand/shop/list`, params),
  // 门店列表
  SaveBrandShop: params =>
    post(`/admin/v1/activity/brand/shop/save`, params),
  // 获取活动规则
  getActivityRule: params => post(`/admin/v1/activity/rule/get?actId=${params}`,{},{headers:{showLoading:false}}),
  // 保存活动规则
  postActivityRuleSave: params => post(`/admin/v1/activity/rule/save`, params,{headers:{showLoading:false}}),
  // 获取用户协议
  getActivityAgreement: params => post(`/admin/v1/activity/agreement/get?actId=${params}`,{},{headers:{showLoading:false}}),
  // 保存用户协议
  postActivityAgreementSave: params => post(`/admin/v1/activity/agreement/save`, params,{headers:{showLoading:false}}),
  // 战区列表
  postListBrandGeneral: params => post(`/admin/v1/activity/brand/list_brand_general`, params),
  // 保存或者编辑战区
  postSaveOrUpdateBrand: params => post(`/admin/v1/activity/brand/save_or_update`, params),
  // 移除战区
  deleteBrandGeneral: id => post(`/admin/v1/activity/brand/del_brand_general?id=${id}`),
  // 批量添加军团
  postAddBrandCat: params => post(`/admin/v1/activity/brand/add_brand_cat`, params),
  // 移除军团
  deleteBrandGeneralCat: id => post(`/admin/v1/activity/brand/remove_brand_cat?id=${id}`),
  // 导出战区
  postExportBrandGeneral: params => post(`/admin/v1/activity/brand/export_brand_general`, params),
  // 获取分享设置权限（朋友圈）
  getShareMomentFlag: params => post(`/admin/v1/activity/share_moment_flag/get?actId=${params}`),
  // 设置分享设置权限（朋友圈）
  setShareMomentFlag: params => post(`/admin/v1/activity/share_moment_flag/save`, params),
}
